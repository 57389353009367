<template>
  <Layout>
    <div class="app-charge">
      <h2 style="margin-top:10px;">购买兑换码</h2>
      <a-row class="fee-list" :gutter="16">
        <a-col :span="12" v-for="(item, index) in codeList" :key="item.code">
          <div
            class="fee-item"
            :class="current === index ? 'active' : null"
            @click="select(index)"
          >
            <div class="price"><small>￥</small>{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </a-col>
      </a-row>
      <a-button
        :disabled="!enablePay"
        @click="pay"
        size="large"
        type="primary"
        block
        :loading="submiting"
        style="margin-top:10px;height:44px;"
      >
        微信支付
      </a-button>

      <h2 style="margin-top:10px;">我的兑换码</h2>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
        />
      </div>

      <!-- 付款二维码 -->
      <a-modal
        :destroyOnClose="true"
        :footer="null"
        :visible="dialogVisible"
        @cancel="closeDialog"
        :bodyStyle="{
          'text-align': 'center'
        }"
      >
        <div class="title">
          微信扫码付款
        </div>
        <vue-qrcode :width="200" :value="qrcode" />
        <div>
          如果付款后长时间没有到账，请联系客服
        </div>
      </a-modal>
    </div>
  </Layout>
</template>

<script>
import { mapState } from "vuex";

import Layout from "@/layout/index.vue";

import VueQrcode from "vue-qrcode";

const OrderSvc = require("@/service/order");
const CodeSvc = require("@/service/code");

export default {
  name: "Code",

  data() {
    return {
      codeList: [
        { code: "code1w", title: "645", desc: "5个兑换码(1万点/月)" },
        { code: "code2w", title: "795", desc: "5个兑换码(2万点/月)" }
      ],
      current: -1,
      submiting: false,

      qrcode: "",
      orderId: "",
      dialogVisible: false,

      list: [],
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false
    };
  },

  computed: {
    ...mapState(["user", "isWeixin"]),

    columns() {
      return [
        {
          title: "兑换码",
          dataIndex: "code",
          key: "code"
        },
        {
          title: "点数",
          dataIndex: "total",
          key: "total"
        },
        {
          title: "状态",
          dataIndex: "used",
          key: "used",
          width: 120,
          customRender: used => (used ? "已使用" : "未使用")
        }
      ];
    },

    enablePay() {
      return this.current >= 0;
    }
  },

  methods: {
    select(index) {
      this.current = index;
    },

    async pay() {
      if (this.current < 0) {
        return;
      }

      this.submiting = true;

      const code = this.codeList[this.current].code;
      const res = await OrderSvc.create(code);
      const { code_url, id } = res;

      this.submiting = false;

      this.qrcode = code_url;
      this.orderId = id;

      this.dialogVisible = true;

      this.timer = setTimeout(this.checkOrderStatus, 5000);
    },

    async checkOrderStatus() {
      try {
        const { status } = await OrderSvc.orderStatus(this.orderId);
        if (status === 1) {
          this.dialogVisible = false;
          this.$message.info("购买成功");

          await this.loadData();
        } else {
          this.timer = setTimeout(this.checkOrderStatus, 5000);
        }
      } catch (err) {
        console.error(err);

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      }
    },

    closeDialog() {
      this.dialogVisible = false;

      if (this.timer) {
        clearTimeout(this.timer);
      }
    },

    async loadData() {
      this.loading = true;

      try {
        this.list = await CodeSvc.query();
        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.loadData();
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },

  components: {
    VueQrcode,
    Layout
  }
};
</script>

<style lang="scss">
.app-charge {
  width: 100%;
  height: 100vh;

  .balance-box {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;

    .balance {
      font-size: 22px;
      color: #f00;
    }
  }

  .fee-list {
    .fee-item {
      padding: 10px;
      margin-bottom: 10px;
      border: 2px solid #eee;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;

      .price {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .desc {
        font-size: 14px;
      }

      &.active {
        border-color: #1890ff;
        color: #1890ff;
      }

      color: #000;
      font-size: 24px;

      small {
        font-size: 18px;
        text-decoration: none;
      }
    }
  }

  .bank {
    padding: 20px;
    font-size: 18px;
    margin-top: 10px;
  }
}
</style>
